import Vue from 'vue'
import AdminTitle from '../components/admin-title/admin-title.vue'

import TpTable from '@/components/tp-table/index.vue'
import FileUpload from '@/components/file-upload/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import TagLv from '@/components/tag-lv/tag-lv.vue'

const components = [AdminTitle, TpTable, FileUpload, Pagination, TagLv]

export default function install() {
  components.forEach(item => Vue.component(item.name, item))
}
